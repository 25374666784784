import getAppSite from '../../../utils/getAppSite';
import addHoverTextProps from '../../Dashboard/helpers/addHoverTextProps';

const disabledUSAProps = () => {
  return getAppSite() === 'usa'
    ? addHoverTextProps({
        disabled: true,
        message: 'Currently disabled for US site',
      })
    : {};
};

export default disabledUSAProps;
