import { useRef, useState, useContext, useEffect, useCallback } from 'react';
import ModalComponent from '../../../Common/Modal';
import DropdownComponent from '../../../Settings/WebsiteFeeds/DropdownComponent';
import normalize from '../../utilities/normalizeString';
import GroupFilters from './GroupFilters';
import CustomScrollbar from '../../../Common/CustomScrollbar';
import { useNavigate } from 'react-router-dom';
import { store } from '../../../Store';
import { useGroupFunctions } from './GroupFunctions';
import thousandCommas from '../../helpers/thousandCommas';
import useIsMobile from '../../utilities/useIsMobile';
import useTopicOrClientWord from '../../hooks/useTopicOrClientWord';
import useGroupsAlertsFunctions from './useGroupsAlertsFunctions';
import Axios from 'axios';
import useGroupFiltersData from './GroupsFiltersData';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import TopicPicker from '../../../Common/TopicPicker';
import { useChangeKeywordsListsOrder } from '../../hooks/useSearchBarUiFunctions';
import { CheckboxFilterItem } from './GroupSelectFilterComponent';
import NoTopicsView from '../../../Settings/Topics/NoTopicsView';

const CreateGroupButton = () => {
  const globalState = useContext(store);
  const { state } = globalState;
  const { activePlan, contentResults, activeResults } = state ?? {};
  const { Groups } = contentResults[activeResults];
  const { maxNumberOfGroups } = activePlan;
  const allowCreateGroups = maxNumberOfGroups === null || Groups?.length < maxNumberOfGroups;
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile(768);
  const modalRef = useRef();

  return (
    <>
      {!!Groups && (
        <button
          data-tooltip-id={'general-tooltip'}
          data-tooltip-content={!allowCreateGroups ? 'Upgrade your subscription to create more groups' : ''}
          data-tooltip-place={'bottom'}
          className={` ${isMobile ? 'add-to-contact-mobile-button' : `general-button sort-button d-lg-block top-nav-create-contact-button px-4`} ${!allowCreateGroups ? 'button-blocked' : ''}`}
          onClick={() => {
            if (allowCreateGroups) {
              setIsOpen(true);
            }
          }}
        >
          Create new group {!allowCreateGroups && <span className='icon-padlock ml-2 d-inline-block-centered' />}
        </button>
      )}
      <CreateGroupModal openState={{ setIsOpen, isOpen }} modalRef={modalRef} key={`modal-open-state-${isOpen}`} />
    </>
  );
};

const CreateGroupModal = (props) => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { activePlan, contentResults, activeResults, team, keywordsLists, group } = state;
  const { enableDynamicGroups } = activePlan;

  const { Groups, CrmContacts } = contentResults[activeResults];

  const { addContactsToGroup, defaultView, defaultType, openState, setIsOpenDelete, modalRef, goToStart } = props;
  const { isOpen, setIsOpen } = openState ?? {};

  const initialGroupState = {
    name: '',
    description: '',
    type: defaultType ?? (!enableDynamicGroups ? 'Static' : 'Dynamic'),
    filters: [{ groupFilters: [] }],
  };

  const [groupInformation, setGroupInformation] = useState(group ?? initialGroupState);

  const [scrollBarPosition, setScrollbarPosition] = useState(0);
  const { name, description, type, filters } = groupInformation ?? {};
  const navigate = useNavigate();

  useEffect(() => {
    if (!!group) {
      setGroupInformation(group);
    }
  }, [group]);

  const handleChange = ({ property, newValue }) => {
    let newGroupInfo = { ...groupInformation };
    newGroupInfo = {
      ...newGroupInfo,
      [property]: newValue,
    };
    setGroupInformation(newGroupInfo);
  };

  const [isLoading, setIsLoading] = useState(false);
  const typeOptions = ['Dynamic', 'Static'];
  const [currentStep, setCurrentStep] = useState('selection');
  let initialFiltersNumber = CrmContacts && group ? CrmContacts?.totalHits : 0;
  const [filtersNumber, setFiltersNumber] = useState(initialFiltersNumber);
  const [loadingPreselectedFilter, setLoadingPreselectedFilter] = useState(null);
  const scrollbarRef = useRef();
  const { changeGroupAlert, getGroupsAlerts } = useGroupsAlertsFunctions();
  const { saveGroup, createDynamicQueries, getGroupInformation, adjustGroupInformation, allowProceedSaveGroup } =
    useGroupFunctions();
  const isMobile = useIsMobile();
  const { newAgencyPlan } = useTopicOrClientWord();
  const { groupsPreselectedFiltersData } = useGroupFiltersData();

  const { changeKeywordsListsOrder } = useChangeKeywordsListsOrder({
    keywordsLists: keywordsLists.filter((item) => item.id !== null),
  });
  const keywordsListsToUse = changeKeywordsListsOrder();

  const listsToUse = keywordsListsToUse
    ?.filter((item) => item?.id !== null)
    ?.map((item) => {
      return { ...item, filterValue: `${item?.id}` };
    });

  useEffect(() => {
    if (defaultView) {
      setCurrentStep(defaultView);
    }
  }, [defaultView]);

  const initialCall = useRef();
  initialCall.current = async (source) => {
    try {
      await getGroupsAlerts({ source });
    } catch (error) {}
  };

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    if (!group) {
      //OP: get alerts data if it's creation modal, to use data for creating alert
      initialCall.current(source);
    }
    return () => {
      source.cancel(`Groups alerts cancelled by the user.`);
    };
  }, [group]);

  const createDisabledCondition = () => {
    if (group) {
      if (currentStep === 'filters') {
        return JSON.stringify(filters) === JSON.stringify(group?.filters);
      } else {
        return (name === group?.name && description === group?.description) || !name || name === '';
      }
    } else {
      const flatFilters = createDynamicQueries(filters).flatMap((item) => item.filters);
      const nationsFilterCondition = flatFilters.find(
        (item) => item.field === 'categories' && typeof item.value === 'undefined' && typeof item.values === 'undefined'
      );
      return (
        (type === 'Dynamic' && (flatFilters?.length === 0 || allowProceedSaveGroup(filters))) ||
        (type === 'Static' && name === '') ||
        nationsFilterCondition
      );
    }
  };

  const saveGroupFunction = useCallback(
    async (group) => {
      try {
        const result = await saveGroup({ groupInformation: adjustGroupInformation(group ?? groupInformation) });
        if (!!result) {
          if (addContactsToGroup) {
            await addContactsToGroup({ selectedGroup: result });
          } else {
            const newGroups = [...Groups, result];
            const GroupsResults = {
              ...contentResults,
              [activeResults]: {
                Groups: newGroups,
              },
            };
            dispatch({
              type: 'MODIFY_SECTION',
              parameter: 'contentResults',
              value: GroupsResults,
            });
          }
          await changeGroupAlert({ type: 'DailyCrmGroupChanges', groupId: result.id });
          navigate({ pathname: `/influence/my-groups/${result?.id}` });
        }
      } catch (err) {}
    },
    [addContactsToGroup, Groups, contentResults, activeResults, groupInformation]
  );

  const applyFilterSelect = useCallback(async (filter, i) => {
    const { label, plainDescription, description, filters } = filter;
    let expandedCondition = filters?.some((groupFilter) => groupFilter?.expandedFilter);
    if (expandedCondition) {
      let newFilters = [...filters];
      let newFilterInformation = { ...newFilters[0], isSaved: true };
      newFilterInformation[newFilterInformation?.category?.name === 'Sentiment' ? 'sentimentTopic' : 'value'] =
        listsToUse[0];
      newFilters[0] = newFilterInformation;
      setGroupInformation({
        ...groupInformation,
        name: label,
        description: plainDescription ?? description,
        filters: [{ groupFilters: newFilters }],
      });
      setCurrentStep('chooseTopic');
    } else {
      setLoadingPreselectedFilter(i);
      const groupInfo = {
        ...groupInformation,
        name: label,
        description: plainDescription ?? description,
        filters: [{ groupFilters: filters }],
      };
      await saveGroupFunction(groupInfo);
      setLoadingPreselectedFilter(null);
    }
  }, []);

  const resetFilterSelect = useCallback(() => {
    setIsLoading(false);
    setGroupInformation(initialGroupState);
    setFiltersNumber(initialFiltersNumber);
  }, [initialGroupState, initialFiltersNumber]);

  const setFilterInformation = (filter) => {
    let newGroupInfo = { ...groupInformation };
    let newGroupFilters = [...groupInformation?.filters?.[0]?.groupFilters];
    newGroupFilters = [
      ...newGroupFilters.map((item) => (item?.category?.name === filter?.category?.name ? filter : item)),
    ];
    newGroupInfo = { ...newGroupInfo, filters: [{ groupFilters: newGroupFilters }] };
    setGroupInformation(newGroupInfo);
  };

  const preselectedFilterDisabledCondition = () => {
    return loadingPreselectedFilter !== null || !enableDynamicGroups;
  };

  return (
    <ModalComponent
      overlayClassName={'react-modal-overlay modal-close-position-adjustment'}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      maxWidth={currentStep === 'selection' ? 830 : currentStep === 'chooseTopic' ? 600 : 680}
      modalRef={modalRef}
    >
      {currentStep !== 'selection' && currentStep !== 'chooseTopic' && (
        <div className='border-bottom pt-4  pb-3 px-lg-5 px-4'>
          <h3 className='mb-0 pr-4 w-100 hidden-lines hidden-one-line title-h4-bold'>
            <span className='font-weight-bold d-inline-block' style={{ height: '18px' }}>
              {currentStep === 'selection'
                ? 'Start from scratch'
                : currentStep === 'create'
                  ? `${group ? name : `Create group ${newAgencyPlan ? `for ${team?.teamName}` : ''}`}`
                  : `${group ? 'Edit' : 'Set'} your filters`}
            </span>
          </h3>
        </div>
      )}
      {currentStep === 'selection' && (
        <div className='pt-4 pb-5'>
          <div className='py-4 px-4 px-lg-5'>
            <h3 className='title-h4-bold'>Start from scratch</h3>
            <button className='action-button rounded-button px-5 py-2 my-3' onClick={() => setCurrentStep('create')}>
              Create a group from scratch
            </button>
          </div>
          <div className='mb-4 px-4 px-lg-5'>
            <h5 className='title-h4-bold mb-1'>Or choose a quick starter</h5>
            <span className='light-60-text'>You can customise, add, or adjust group filters at any time</span>
          </div>
          <div className='group-filters-grid-container pl-4 pl-lg-5'>
            <CustomScrollbar
              className='simple-scrollbar groups-preselected-filters-scrollbar'
              maximalThumbYSize={100}
              style={{ height: `calc(90vh - 320px)` }}
            >
              <div className='reports-default-popup pb-3'>
                {groupsPreselectedFiltersData.map((filter, i) => {
                  const { label, description } = filter;
                  return (
                    <button
                      onClick={() => applyFilterSelect(filter, i)}
                      className='general-button d-block text-main-blue link-main-blue text-decoration-none position-relative'
                      disabled={preselectedFilterDisabledCondition()}
                      data-tooltip-id={'modal-tooltip'}
                      data-tooltip-content={
                        !enableDynamicGroups ? 'Upgrade to Mogul or Enterprise to create dynamic groups' : ''
                      }
                      data-tooltip-float={true}
                    >
                      <div className='border rounded text-center hovered-button h-100'>
                        <h4 className='title-h5-bold'>{label}</h4>
                        <p className='main-light-text paragraph-p2 mb-0'>{description}</p>
                      </div>
                      {loadingPreselectedFilter === i && (
                        <div className='preselected-group-filter-loader d-flex align-items-center justify-content-center border'>
                          <i className='fas fa-spinner fa-spin title-h2'></i>
                        </div>
                      )}
                      {!enableDynamicGroups && <span className='icon-padlock main-light-text' />}
                    </button>
                  );
                })}
              </div>
            </CustomScrollbar>
          </div>
        </div>
      )}
      {currentStep === 'chooseTopic' && (
        <>
          {keywordsLists.length > 0 ? (
            <>
              <ChooseTopicCreateGroup
                filterInformation={groupInformation?.filters?.[0]?.groupFilters?.find(
                  (item) => item?.category?.filterName === 'topicMatchedKeywordListIds'
                )}
                setFilterInformation={setFilterInformation}
                listsToUse={listsToUse}
                title={`Choose a topic for which you want to see ${groupInformation.name?.split(' ')?.[0]?.toLowerCase()} contributors`}
              />
              <NationsSelect
                title={`Choose nations for which you want to see ${groupInformation.name?.split(' ')?.[0]?.toLowerCase()} contributors`}
                filterInformation={groupInformation?.filters?.[0]?.groupFilters?.find(
                  (item) => item?.category?.name === 'Nation'
                )}
                setFilterInformation={setFilterInformation}
              />
            </>
          ) : (
            <div className='pt-5 px-3 px-lg-5'>
              <NoTopicsView />
            </div>
          )}
        </>
      )}
      {currentStep === 'create' && (
        <div className='px-lg-5 px-4 pt-4 pb-0 position-relative'>
          <div className='form-group mb-4'>
            <label className='font-weight-bold' htmlFor='groupName'>
              Group name
            </label>
            <input
              className='main-input w-100 form-control'
              value={name}
              onChange={(e) => {
                handleChange({ property: 'name', newValue: e.target?.value });
              }}
              autoFocus={true}
              type={'text'}
              id='groupName'
              autoComplete='off'
            />
          </div>

          <div className='form-group mb-4'>
            <label className='font-weight-bold' htmlFor='groupTags'>
              <span className='font-weight-bold'>Group type</span>
              <span
                data-tooltip-class-name='general-tooltip pricing-feature-tooltip report-popup-tooltip'
                data-tooltip-id='general-tooltip'
                data-tooltip-content={
                  'Dynamic groups update automatically over time when contacts match the filter conditions you create. Static groups do not update automatically, and instead represent a single moment in time'
                }
                className='ml-1 icon-info-circle-filled title-h3 d-inline-block-centered light-50-text '
              />
            </label>
            <div
              data-tooltip-class-name='general-tooltip pricing-feature-tooltip report-popup-tooltip'
              data-tooltip-id='group-type-select-tooltip'
              data-tooltip-content={group ? "It's not possible to change a group's type once it has been saved" : ''}
              data-disabled={!!group}
              className='group-type-select-dropdown'
            >
              {' '}
              <DropdownComponent
                mainItem={() => (
                  <>
                    <span className={`group-type group-type-${normalize(type)}`} />
                    {type}
                  </>
                )}
              >
                <ul className='policy-list px-0 pt-1 m-0 pb-2'>
                  {typeOptions?.map((item) => {
                    const notDynamicEnabledCondition = item === 'Dynamic' && !enableDynamicGroups;
                    return (
                      <li
                        className={`list-action-button px-3 py-2 flex-centered justify-content-between pointer ${notDynamicEnabledCondition ? 'main-light-text' : ''}`}
                        key={`item-${item}`}
                        onClick={() => {
                          if (item === 'Static' || (item === 'Dynamic' && enableDynamicGroups)) {
                            handleChange({ property: 'type', newValue: item });
                          }
                        }}
                        data-tooltip-id={'modal-tooltip'}
                        data-tooltip-content={
                          notDynamicEnabledCondition ? 'Upgrade to Mogul or Enterprise to create dynamic groups' : ''
                        }
                        data-tooltip-float={true}
                      >
                        <span>
                          <span className={`group-type group-type-${normalize(item)}`} />
                          {item}
                          {notDynamicEnabledCondition && (
                            <span className='icon-padlock ml-2 d-inline-block-centered main-light-text' />
                          )}
                        </span>
                        {type === item && <span className='icon-tick paragraph-p5' />}
                      </li>
                    );
                  })}
                </ul>
              </DropdownComponent>
            </div>
          </div>

          <div className='form-group mb-0'>
            <label htmlFor='groupTags'>
              <span className='font-weight-bold'>Description</span> (optional){' '}
            </label>
            <textarea
              autoComplete='off'
              className='main-input writing-textarea '
              value={description}
              onChange={(e) => {
                handleChange({ property: 'description', newValue: e.target?.value });
              }}
            />
          </div>
        </div>
      )}
      {currentStep === 'filters' && (
        <CustomScrollbar
          autoHeight
          autoHeightMin='100%'
          autoHeightMax='55vh'
          className='simple-scrollbar'
          maximalThumbYSize={100}
          ref={scrollbarRef}
          onScroll={(scrollValues) => {
            setScrollbarPosition(scrollValues?.scrollTop);
          }}
        >
          <div className='px-lg-5 px-4 pt-4 pb-0 position-relative'>
            <GroupFilters
              scrollbarRef={scrollbarRef}
              scrollBarPosition={scrollBarPosition}
              filtersState={{
                filters,
                setFilters: (value) => {
                  handleChange({ property: 'filters', newValue: value });
                },
                setFiltersNumber,
              }}
            />
          </div>
        </CustomScrollbar>
      )}

      {currentStep !== 'selection' && (
        <div
          className={`bg-main-white ${currentStep === 'create' ? 'pt-4 pb-5' : 'create-group-actions-bar'} px-4 px-lg-5 flex-centered justify-content-${group && currentStep === 'create' ? 'between' : 'end'} rounded-bottom ${currentStep === 'chooseTopic' ? 'pb-4' : ''}`}
        >
          {group && currentStep === 'create' && (
            <button
              className='nowrap-item general-button text-red'
              onClick={() => {
                if (setIsOpenDelete) {
                  setIsOpenDelete(true);
                  setIsOpen(false);
                }
              }}
            >
              Delete group
            </button>
          )}
          <div className={`flex-centered ${currentStep === 'chooseTopic' ? 'pb-2' : ''}`}>
            {(currentStep === 'filters' || currentStep === 'create' || currentStep === 'chooseTopic') && !group && (
              <button
                className={`outline-active-button px-4 py-2 general-button mr-3 ${currentStep === 'chooseTopic' ? 'rounded-button' : 'general-button'} `}
                onClick={() => {
                  if (currentStep === 'filters' && !group) {
                    setCurrentStep('create');
                  } else if (currentStep === 'create' || currentStep === 'chooseTopic') {
                    if (goToStart) {
                      goToStart();
                    } else {
                      setCurrentStep('selection');
                      resetFilterSelect();
                    }
                  }
                }}
              >
                <span className='icon-long-arrow-left text-light-blue paragraph-p6 mr-2 d-inline-block-centered' />
                Back
              </button>
            )}
            {(currentStep === 'filters' ||
              (currentStep === 'chooseTopic' && keywordsLists?.length !== 0) ||
              group ||
              type === 'Static') && (
              <button
                className={`action-button px-4 py-2 ${currentStep === 'chooseTopic' ? 'rounded-button' : 'general-button'}`}
                disabled={createDisabledCondition() || isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  if (group) {
                    const result = await saveGroup({
                      groupInformation: adjustGroupInformation(groupInformation),
                      update: true,
                    });
                    if (!!result) {
                      dispatch({
                        type: 'MODIFY_SECTION',
                        parameter: 'group',
                        value: getGroupInformation({ group: result }),
                      });
                      setIsLoading(false);
                      setIsOpen(false);
                    }
                  } else {
                    saveGroupFunction();
                  }
                }}
              >
                {isLoading && <i className='fas fa-spinner fa-spin mr-2' />}{' '}
                {currentStep === 'chooseTopic' ? 'Create' : 'Save'} {group ? 'changes' : 'group'}
                {addContactsToGroup ? ' and add contacts' : ''}
              </button>
            )}
          </div>
          {currentStep === 'create' && type !== 'Static' && !group && (
            <button
              className='action-button px-4 py-2'
              onClick={() => {
                setCurrentStep('filters');
              }}
              disabled={name === ''}
            >
              Set your filters <span className='icon-long-arrow-right paragraph-p5 ml-2' />
            </button>
          )}
        </div>
      )}
      {currentStep === 'filters' && (
        <div
          className={`border-top  pt-3 flex-centered px-lg-5 px-4 justify-content-${currentStep === 'create' ? 'end' : 'between'}`}
          style={{ paddingBottom: '18px' }}
        >
          <p className='mb-0 nowrap-item'>
            <span className='d-none d-lg-inline-block'>This is a</span>{' '}
            <span className='font-weight-bold'>{isMobile ? type : type?.toLowerCase()} group</span> containing{' '}
            {thousandCommas(filtersNumber)} contact{filtersNumber === 1 ? '' : 's'}
          </p>
        </div>
      )}
      <ReactTooltip
        id='group-type-select-tooltip'
        effect={'solid'}
        className={'header-tooltip'}
        arrowColor={'transparent'}
        place={'top'}
        openOnClick
      />
    </ModalComponent>
  );
};

const ChooseTopicCreateGroup = (props) => {
  const { filterInformation, setFilterInformation, listsToUse, title } = props;
  const { category, value } = filterInformation ?? {};
  const { name } = category ?? {};
  const modifyFilterInformation = ({ property, value }) => {
    let newFilterInformation = { ...filterInformation, isSaved: true };
    newFilterInformation[property] = value;
    setFilterInformation(newFilterInformation);
  };

  return (
    <>
      <h3 className='title-h4-bold px-4 mr-5 mr-md-0 px-lg-5 py-4 section-title'>
        Choose a topic to identify most{' '}
        {name === 'Vocal about...' ? 'vocal' : name === 'Sentiment' ? value?.name?.toLowerCase() : ''} policymakers
      </h3>
      <div className='px-4 px-lg-5 pb-4 select-topic-create-group'>
        <div className='pt-4 pb-3'>
          <h5 className='title-h5-bold pb-2'>{title}</h5>
          <TopicPicker
            keywordsLists={listsToUse}
            showTopicCases={true}
            searchFunction={({ topic }) => {
              modifyFilterInformation({ property: name === 'Sentiment' ? 'sentimentTopic' : 'value', value: topic });
            }}
            placeholder={`Select a topic`}
            showTopicSelected={true}
            dataTooltip={'modal-tooltip'}
          />
        </div>
      </div>
    </>
  );
};

const NationsSelect = ({ title, filterInformation, setFilterInformation }) => {
  const { GroupsFiltersData } = useGroupFiltersData();
  const nationFilter = GroupsFiltersData.categories.find((item) => item.name === 'Nation');
  const { options } = nationFilter ?? {};
  const { value } = filterInformation ?? {};

  const modifyFilterInformation = ({ property, value }) => {
    let newFilterInformation = { ...filterInformation, isSaved: true };
    newFilterInformation[property] = value;
    setFilterInformation(newFilterInformation);
  };

  return (
    <div className='px-4 px-lg-5 group-choose-topic-popup'>
      <h5 className='title-h5-bold pb-2'>{title}</h5>
      <div className=''>
        {options.map((option) => {
          return (
            <CheckboxFilterItem
              key={`item-checkbox-${option?.name}`}
              item={option}
              value={value ?? []}
              modifyFilterInformation={modifyFilterInformation}
              className={'px-0'}
            />
          );
        })}
      </div>
    </div>
  );
};

export { CreateGroupButton, CreateGroupModal };
