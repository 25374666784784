import getAppSite from '../utils/getAppSite';

class ConfigDataClass {
  getConfig() {
    try {
      const configData = require(`./${getAppSite()}.json`);
      return configData;
    } catch (e) {}
  }
}

const SiteConfigData = new ConfigDataClass();
const ConfigData = SiteConfigData.getConfig();
export default ConfigData;
